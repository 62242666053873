import React, { Suspense } from 'react'

import { BrowserRouter, Route, Routes } from 'react-router-dom'

import './App.css' // global css
import { CollaboratorIdProvider } from 'container/CollaboratorContainer'
import { GraphqlProvider } from 'container/GraphqlContainer'
import { MuiCustomThemeProvider } from 'theme'
import { Head, HeadContextProvider } from 'utilities/Head'
import { usePageViews } from 'utilities/GoogleAnalytics'
import { useSalesChannel } from 'utilities/SalesChannel'
import { ReferrerProvider } from 'utilities/CommonHooks'
import { CompanyProvider } from 'container/CompanyContainer'

// it could be your App.tsx file or theme file that is included in your tsconfig.json

const environment = process.env.REACT_APP_ENV // development | staging | production

/* Lazy load pages */

const LpHome = React.lazy(() =>
  import('./pages/LP/LpHome').then(({ LpHome }) => ({
    default: LpHome,
  }))
)

const Product = React.lazy(() =>
  import('container/ProductContainer').then(({ ProductContainer }) => ({
    default: ProductContainer,
  }))
)

const Reciever = React.lazy(() =>
  import('container/RecieverContainer').then(({ RecieverContainer }) => ({
    default: RecieverContainer,
  }))
)

const MemberContainer = React.lazy(() =>
  import('./container/MemberContainer').then(({ MemberContainer }) => ({
    default: MemberContainer,
  }))
)

const BlogContainer = React.lazy(() =>
  import('./container/BlogContainer').then(({ BlogContainer }) => ({
    default: BlogContainer,
  }))
)

const FAQ = React.lazy(() =>
  import('./pages/LP/FAQ').then(({ FAQ }) => ({
    default: FAQ,
  }))
)

const Stage = React.lazy(() =>
  import('./utilities/Stage').then(({ Stage }) => ({
    default: Stage,
  }))
)

const ProductCheck = React.lazy(() =>
  import('./utilities/ComponentLibrary').then(({ ComponentLibrary }) => ({
    default: ComponentLibrary,
  }))
)

const Privacy = React.lazy(() =>
  import('./pages/LP/Privacy').then(({ Privacy }) => ({
    default: Privacy,
  }))
)

const Term = React.lazy(() =>
  import('./pages/LP/Term').then(({ Term }) => ({
    default: Term,
  }))
)

const Tokushou = React.lazy(() =>
  import('./pages/LP/Tokushou').then(({ Tokushou }) => ({
    default: Tokushou,
  }))
)

const ErrorPage = React.lazy(() =>
  import('./pages/ErrorPage').then(({ ErrorPage }) => ({
    default: ErrorPage,
  }))
)

// fallback用
const Loading: React.FC = () => (
  <p style={{ paddingTop: '6rem', textAlign: 'center' }}>Loading...</p>
)

// コンテクストのプロバイダーでラップ
// NOTICE: materialUIのthemeや認証情報など、コンテクストのプロバイダーは今後増えていく
const App = (): JSX.Element => {
  return (
    <HeadContextProvider>
      <MuiCustomThemeProvider>
        <BrowserRouter>
          <CollaboratorIdProvider>
            <CompanyProvider>
              <GraphqlProvider>
                <ReferrerProvider>
                  <TopLevelRoutes />
                </ReferrerProvider>
              </GraphqlProvider>
            </CompanyProvider>
          </CollaboratorIdProvider>
        </BrowserRouter>
      </MuiCustomThemeProvider>
    </HeadContextProvider>
  )
}

const TopLevelRoutes = () => {
  usePageViews()
  useSalesChannel()

  return (
    <>
      <Routes>
        {/* For local and staging test */}
        {environment !== 'production' && (
          <Route
            path="test"
            element={
              <React.Fragment>
                <Head></Head>
                <Suspense fallback={<Loading />}>
                  <Stage></Stage>
                </Suspense>
              </React.Fragment>
            }
          />
        )}
        {/* LP */}
        <Route
          index
          element={
            <React.Fragment>
              <Head
                title="相手が選べるソーシャルギフト｜ZEFT（ゼフト）"
                description="ZEFTは選んだ3つの中から相手が1つ選べるソーシャルギフト。住所を知らなくてもSNS・LINEなど、オンラインで簡単に贈れます。"
              ></Head>
              <Suspense fallback={<Loading />}>
                <LpHome></LpHome>
              </Suspense>
            </React.Fragment>
          }
        />
        {/* FAQ */}
        <Route
          path="faq"
          element={
            <React.Fragment>
              <Head title="よくあるご質問｜ZEFT ゼフト"></Head>
              <Suspense fallback={<Loading />}>
                <FAQ></FAQ>
              </Suspense>
            </React.Fragment>
          }
        />
        {/* Sender App */}
        <Route
          path="product/*"
          element={
            <Suspense fallback={<Loading />}>
              <Product></Product>
            </Suspense>
          }
        />
        {/* Reciever App */}
        <Route
          path="gift/*"
          element={
            <React.Fragment>
              <Head description="The gift for you"></Head>
              <Suspense fallback={<Loading />}>
                <Reciever></Reciever>
              </Suspense>
            </React.Fragment>
          }
        />
        {/* Member Pages */}
        <Route
          path="member/*"
          element={
            <React.Fragment>
              <Head description="Member page"></Head>
              <Suspense fallback={<Loading />}>
                <MemberContainer />
              </Suspense>
            </React.Fragment>
          }
        />
        {/* For buyers to check items */}
        <Route
          path="stagecheck"
          element={
            <React.Fragment>
              <Head></Head>
              <Suspense fallback={<Loading />}>
                <ProductCheck></ProductCheck>
              </Suspense>
            </React.Fragment>
          }
        />

        {/* For Privacy */}
        <Route
          path="privacy"
          element={
            <React.Fragment>
              <Head title="プライバシーポリシー｜ZEFT ゼフト" />
              <Suspense fallback={<Loading />}>
                <Privacy></Privacy>
              </Suspense>
            </React.Fragment>
          }
        />

        {/* For Term */}
        <Route
          path="term"
          element={
            <React.Fragment>
              <Head title="利用規約｜ZEFT ゼフト" />
              <Suspense fallback={<Loading />}>
                <Term></Term>
              </Suspense>
            </React.Fragment>
          }
        />

        {/* For Tokushou */}
        <Route
          path="tokushou"
          element={
            <React.Fragment>
              <Head title="特定商取引法に基づく表記｜ZEFT ゼフト" />
              <Suspense fallback={<Loading />}>
                <Tokushou></Tokushou>
              </Suspense>
            </React.Fragment>
          }
        />

        {/* For Blog */}
        <Route
          path="blog/*"
          element={
            <React.Fragment>
              <Head title="ブログ｜ZEFT ゼフト" />
              <Suspense fallback={<Loading />}>
                <BlogContainer />
              </Suspense>
            </React.Fragment>
          }
        />

        {/* default routes */}
        <Route
          path="*"
          element={
            <React.Fragment>
              <Head title="エラー｜ZEFT ゼフト"></Head>
              <Suspense fallback={<Loading />}>
                <ErrorPage></ErrorPage>
              </Suspense>
            </React.Fragment>
          }
        />
      </Routes>
    </>
  )
}

export default App
