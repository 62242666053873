import React, { ReactNode, useEffect, useState, createContext, useContext } from 'react'

import { useLocation } from 'react-router-dom'

import { webAuth, initManagement } from 'utilities/auth0'

type Company = { slug: string; suppliers: Array<string> }

// コラボ相手のIDを取得. プロバイダーコンポーネントでコンテクストに入れて渡す
const useCompanyFromBackend = (): Company & { loginUserEmail: string } => {
  const [loginUserEmail, setLoginUserEmail] = useState<string>('')
  const [company, setCompany] = useState<Company>({ slug: '', suppliers: [] })
  const { pathname } = useLocation()

  // fetch login user info from auth0
  useEffect(() => {
    webAuth.checkSession({}, function (err, authResult) {
      console.log('webAuth authResult')
      console.log(authResult)
      console.log('webAuth err')
      console.log(err)
      if (err?.code === 'login_required') {
        return
      }
      if (!!err || !authResult) {
        console.error('check session error')
        console.error(err)
        return
      }
      const management = initManagement(authResult.accessToken)
      const userId = authResult.idTokenPayload.sub
      management.getUser(userId, function (err, profile) {
        if (!!err) {
          console.error('get user error')
          console.error(err)
          return
        }
        setLoginUserEmail(profile.email || '')
      })
    })
  }, [])

  // fetch company info from zeft bachkend
  useEffect(() => {
    const fetcher = async () => {
      const apiUrl = process.env.REACT_APP_CLOUD_RUN_CADU_API_URL
      const res = await fetch(`${apiUrl}/company?supplier=${loginUserEmail}`)
      const json = await res.json()
      setCompany(json)
    }
    if (!!loginUserEmail) {
      fetcher()
    }
  }, [loginUserEmail, pathname])

  console.log('loginUserEmail')
  console.log(loginUserEmail)
  console.log('company')
  console.log(company)

  return { loginUserEmail, ...company }
}

// コンテクスト作成
const Context = createContext<(Company & { loginUserEmail: string }) | undefined>(
  undefined
)

// プロバイダーコンポーネント
const CompanyProvider = (props: { children: ReactNode }): JSX.Element => {
  const company = useCompanyFromBackend()

  return <Context.Provider value={company}>{props.children}</Context.Provider>
}

// コンシューマコンポーネント用のフック
const useCompanyId = (): string | null => {
  //コンテクストからID取得
  const company = useContext(Context)
  return !!company ? company.slug : ''
}

const useSupplierEmails = (): [string, Array<string>] => {
  const value = useContext(Context)
  const allEmails = !!value ? value.suppliers : []
  const loginUser = !!value ? value.loginUserEmail : ''
  return [loginUser, allEmails]
}

export { CompanyProvider, useCompanyId, useSupplierEmails }
