import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

/**
 * windowsオブジェクトにdataLayerインターフェースを追加
 * for dataLayer used in google tag manager
 */
declare global {
  interface Window {
    dataLayer: Array<unknown>
    google_optimize: {
      get: (experimentId: string) => string | undefined
    }
  }
}

/*
 * datalayerに贈るイベントを抽象化した型
 * any型は抽象度が高すぎたので不可
 */
/* type SomeEvent = { */
/*   event: string */
/* } */

type DataLayerItem =
  /* | SomeEvent */
  | QuizAnswer
  | Purchase
  | LinkCreated
  | RecieverAddressCompleted
  | GiftAdded
  | GiftBoxFullfilled
  | ShippingInfoSubmitted
  | PageView
  | TooltipViewed
  | SnsOpened
  | LinkCopied
  | CreateLinkInfoSubmitted
  | PaymentInfoSubmitted

const dataLayerPush = function (data: DataLayerItem): void {
  // Show event when in development environments
  if (process.env.REACT_APP_ENV !== 'production') {
    console.log('Debug event data ' + JSON.stringify(data, null, 2))
  }
  if (window.dataLayer !== undefined) {
    window.dataLayer.push(data)
  }
}

// パターンID('0' , '1')を取得する
function useExperiment(experimentId: string): string | undefined {
  const [variant, setVariant] = useState<string | undefined>(undefined)
  useEffect(() => {
    if (process.env.REACT_APP_ENV !== 'production') {
      return
    }
    const timer = setInterval(() => {
      console.log('loading google optimize...')
      if (window.google_optimize !== undefined) {
        setVariant(window.google_optimize.get(experimentId))
        clearInterval(timer)
      }
    }, 300)
  }, [])
  return variant
}

/*
 * クイズ回答時に送信するデータ
 * 独自イベント
 */
type QuizAnswer = {
  event: 'onClickAnswerButton'
  quiz: {
    id: string | undefined
    name: string | undefined
  }
  answer: {
    id: string
    name: string
  }
}

/*
 * 決済完了時に送信するデータ
 * google analytics 4 推奨イベント.
 * https://developers.google.com/gtagjs/reference/ga4-events#purchase
 * それプラス、ギフト候補として選択された商品IDをselectedに設定.
 */

type Purchase = {
  event: 'onPurchaseSucceeded'
  purchaseParam: {
    currency: 'JPY'
    transaction_id: string
    value: number
    coupon?: string
    items: {
      item_id: string
      item_name: string
    }[]
    selected: string[] // 推奨パラメータでないがカスタマイズとして設定
    how_many_selected: number
  }
}

type RecieverAddressCompleted = {
  event: 'receiver address completed'
}

type LinkCreated = {
  event: 'link created'
  cardToken: string
}

type GiftAdded = {
  event: 'gift added'
  giftId: string
  giftName: string
  giftPrice: number
  sceneOfGiftSelected: string
}

type GiftBoxFullfilled = {
  event: 'gift box fullfilled'
}

type ShippingInfoSubmitted = {
  event: 'shipping info submitted'
  giftRecieved: string
  prefectureSent: string
}

type PageView = {
  event: 'page view'
  path: string
}

type TooltipViewed = {
  event: 'tooltip viewed'
  step: number
}

type SnsOpened = {
  event: 'sns opened'
  snsType: string
}

type LinkCopied = {
  event: 'link copied'
}

type PaymentInfoSubmitted = {
  event: 'payment info submitted'
}

type CreateLinkInfoSubmitted = {
  event: 'create link info submitted'
  totalPriceRange: string
  numberOfGifts: number
  sceneSelected: string
}

export function usePageViews() {
  const { pathname } = useLocation()
  useEffect(() => {
    dataLayerPush({
      event: 'page view',
      path: pathname,
    })
  }, [pathname])
}

export { dataLayerPush, useExperiment }
