import auth0 from 'auth0-js'

// api doc is below
// https://auth0.com/docs/libraries/auth0js

export const webAuth = new auth0.WebAuth({
  // domain must be our custom domain for fix cross-origin issue
  // audience must be ORIGINAL auth0 domain to use Management API
  clientID: process.env.REACT_APP_AUTH0_CLIENT_ID || '',
  redirectUri: process.env.REACT_APP_AUTH0_REDIRECT_URI_FOR_PURCHASE,
  responseType: 'token id_token',
  domain: process.env.REACT_APP_AUTH0_CUSTOM_DOMAIN || '',
  audience: `https://${process.env.REACT_APP_AUTH0_ORIGINAL_DOMAIN}/api/v2/`,
  scope:
    'openid profile email ' +
    'read:current_user ' +
    'create:current_user_metadata update:current_user_metadata delete:current_user_metadata',
})

export const webAuthForUserConsole = new auth0.WebAuth({
  // domain must be our custom domain for fix cross-origin issue
  // audience must be ORIGINAL auth0 domain to use Management API
  clientID: process.env.REACT_APP_AUTH0_CLIENT_ID || '',
  redirectUri: process.env.REACT_APP_AUTH0_REDIRECT_URI_FOR_MEMBER_CONSOLE,
  responseType: 'token id_token',
  domain: process.env.REACT_APP_AUTH0_CUSTOM_DOMAIN || '',
  audience: `https://${process.env.REACT_APP_AUTH0_ORIGINAL_DOMAIN}/api/v2/`,
  scope:
    'openid profile email ' +
    'read:current_user ' +
    'create:current_user_metadata update:current_user_metadata delete:current_user_metadata ' +
    'read:messages', // for custome backend
})

export const initManagement = (token: string) =>
  new auth0.Management({
    domain: process.env.REACT_APP_AUTH0_CUSTOM_DOMAIN || '',
    token: token,
  })
